<template>
<div class="history dirr">
    <div class="timeLine">
        <div :class="'tree ' + treeBkg">
            <p class="dirr tl">{{ $t("history_holy_shrine") }}</p>
            <div class="tabsCon">
                <v-layout row wrap justify-center>
                    <v-btn-toggle v-model="toggle_tabsCon">
                        <v-flex sm6 xs6 md6 lg6>
                            <v-btn outlined min-height="69" value="building" style="padding-bottom: 13px !important;" class="btn" @click="getStages(1, $t('building_stages'), 'bildBkg')">
                                <!-- <div class="img building"></div> -->
                                <p class="tl">{{ $t("building_stages") }}</p>
                            </v-btn>
                        </v-flex>
                        <v-flex sm6 xs6 md6 lg6>
                            <v-btn outlined min-height="69" value="destroying" style="padding-bottom: 13px !important;font-size: 18px !important;" class="btn" @click="getStages(2, $t('destroy_stages'), 'destroyBkg')">
                                <!-- <div class="img destroying"></div> -->
                                <p class="tl">{{ $t("destroy_stages") }}</p>
                            </v-btn>
                        </v-flex>
                    </v-btn-toggle>
                </v-layout>
            </div>
            <div class="timelineCon">
                <v-timeline >
                    <v-timeline-item v-for="item in buildres" :key="item" small color="#000">
                        <template v-slot:opposite>
                            <span :class="'tl timeLtitle font-weight-bold'" v-text="item.title"></span>
                        </template>
                        <div class="py-4">
                            <div class="shortText tl" @click="showMore(item.id, item.title)">{{item.text|subStrArtTitle}} {{$t("view_more")}}</div>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </div>
        <div class="text">
            <div class="item" v-for="i in otherTextres" :key="i">
                <p class="textTitle tl">{{i.title}}</p>
                <p class="textText tx">{{i.text}}</p>
            </div>
        </div>
        <v-dialog v-model="dialog" max-width="900" class="dirr">
            <v-card>
                <v-card-title class="tl" style="letter-spacing: normal !important;">{{this.moreres.title}} - {{this.stagesTitle}}</v-card-title>
                <v-card-text class="" style="white-space: break-spaces; font-size: 17px; line-height: 150%;">{{this.moreres.text}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">{{$t("close")}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>
</template>



<script>

export default {
   
    data() {
        return {
            toggle_tabsCon: 'building',
            stagesTitle : '',
            buildres: [],
            moreres: [],
            otherTextres: [],
            dialog: false,
            treeBkg: '',
        }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
   mounted() {  
    document.title =this.$t("history");  
  }, 
    created () {
        this.getStages (1, this.$t("building_stages"), 'bildBkg');
    },

    methods:{
        getStages (x, i, z) {
            this.stagesTitle = i;
            this.treeBkg = z;
            this.$axios.get("alkafeel-histories/getBySecId/"+x, {
            })
            .then(res => {
                this.buildres = res.data;
                //console.log(res.data);
                this.otherText();
            })
            .catch(() => {
            });
        },
        showMore (x, i) {
            this.moreres.title = i;
            this.$axios.get("alkafeel-histories/getById/"+x, {
            })
            .then(res => {
                this.moreres.text = res.data[0].text;
                //console.log(res.data);
                this.dialog = true;
            })
            .catch(() => {
            });
        },
        otherText () {
            this.$axios.get("alkafeel-histories/getOtherSec", {
            })
            .then(res => {
                this.otherTextres = res.data;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
    },
    filters: {
        subStrArtTitle: function (string) {if (string != null) {return string.substring(0, 230) + ' ... ';}},
    },
}
</script>